import { BrandButton } from "@components/reusables/brandButton";
import { IInformationSection } from "@interface/propsInterface";
import { ISProgramSectionProps } from "@interface/styledComponentInterface";
import { color, screenBreakPoint } from "@styles/styleVariables";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ButtonEnum } from "src/enums/buttonsEnum";
import styled from "styled-components";
import { motion } from "framer-motion";
import { fadeIn, parentAnimation, pushItemDown, pushItemLeft, pushItemRight } from "@styles/animations";

export const InstructorSection = ({
  name,
  briefDescription,
  strapiId,
  coverImage,
  url,
  useTemplate,
}: IInformationSection) => {
  return (
    <SInstructorSection
      variants={parentAnimation(0)}
      whileInView="after"
      initial="before"
    >
      <motion.div variants={pushItemDown} className="instructor-section-description">
        <h2>{name ?? ""}</h2>
        <p>{briefDescription ?? ""}</p>
        {useTemplate ? (
          <BrandButton
            link={url ?? ""}
            name="Learn More!"
            id={strapiId ?? ""}
            category={ButtonEnum.Tertiary}
          ></BrandButton>
        ) : (
          <></>
        )}
      </motion.div>
      <motion.div variants={pushItemRight} className="instructor-section-image-wrapper">
        <GatsbyImage
          image={coverImage ?? ""}
          alt={name ?? ""}
          className="instructor-section-image"
        ></GatsbyImage>
      </motion.div>
    </SInstructorSection>
  );
};

const SInstructorSection = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin: 5rem 0rem;

  .instructor-section-image-wrapper {
    flex: 1;
    width: 100%;
    padding: 2rem;
    .instructor-section-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .instructor-section-description {
    flex: 1;
    h2,
    p {
      margin-bottom: 2rem;
    }

    h2 {
      color: ${color.oxfordBlue};
      border-bottom: solid 0.33rem ${color.sunglow};
      width: fit-content;
    }
  }

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-direction: column;
    align-items: flex-start;
    .instructor-section-image-wrapper {
      width: 100%;
      padding: 2rem 0rem;
    }
  }
`;
