import InstructorInformation from "@templates/instructor-information-template";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { InstructorSection } from "./instructorSection";

export const InstructorsContent = () => {

  const query = useStaticQuery(graphql`
    query instructorInformationQuery {
      instructorInformationQuery: allStrapiInstructorInformation(sort: {fields: rank, order: ASC}) {
        nodes {
          briefDescription
          strapi_id
          name
          rank
          useTemplate
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          sublink {
            url
            name
            main_link {
              url
              name
            }
          }
        }
      }
    }
  `)

  const instructorInformationQuery = query.instructorInformationQuery.nodes;

  return (
    <SInstructorContent>
      {instructorInformationQuery.map((i:any) => 
        <InstructorSection
          name={i?.name ?? ""}
          briefDescription={i?.briefDescription ?? ""}
          strapiId={i?.strapi_id ?? ""}
          coverImage={i?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
          url={`${i?.sublink?.main_link?.url ?? ""}${i?.sublink?.url ?? ""}`}
          buttonName={i?.sublink?.name ?? ""}
          useTemplate={i?.useTemplate ?? false}
          rank={i?.rank ?? "9999"}
        ></InstructorSection>
      )}
    </SInstructorContent>
  )
}

const SInstructorContent  = styled.div`
  min-height: 100vh;
`